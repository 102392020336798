import React, { useContext, useRef } from "react";
import { useEffect } from "react";
import request from "../../../../axios";
import { useState } from "react";
import Modal from "./Modal";
import SelectAddressModal from "./SelectAddressModal";
import { CardDetailsProvider } from "../../../../Context/CardDetailsContext";
import { toast } from "react-toastify";
import { UserContextProvider } from "../../../../Context/UserContext";
import axios from "axios";
import InputComponent from "../components/InputComponent";

const CustomizeLandingPage = ({
  goToTab2,
  setSelectedAddress = () => {},
  submitHandles = () => {},
  cardId,
  setHandleForm = () => {},
  handleForm,
  handleList = [],
  showPreviewButton = false,
  setactiveTab = () => {},
  isCardEditMode,
  inputs,
  setInputs,
  handleValues,
  setHandleValues,
  paymentStatus
}) => {
  let suppported_handles = [
    "about_yourself",
    "phone_number",
    "location",
    "email_id",
  ];

  const [showModal, setShowModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [onAddressSaved, setOnAddressSaved] = useState(false);
  const [cardPhoneNumber, setCardPhoneNumber] = useState("");
  const { cardDetails } = useContext(CardDetailsProvider);
  const { user, setUser } = useContext(UserContextProvider);
  const [visibleFields, setvisibleFields] = useState(() => {
    if (!isCardEditMode) {
      return handleForm.filter((data) => {
        return suppported_handles.includes(data.handle_name);
      });
    } else {
      return handleForm;
    }
  });
  console.log(cardDetails,"cardetails")
  const [fieldErrors, setFieldErrors] = useState({});
  const [sameAsWhatsApp, setSameAsWhatsApp] = useState(false);
  const [aboutYourself, setAboutYourself] = useState("");
  const [location, setLocation] = useState({
    latitude: "",
    longitude: "",
  });

// Create refs for each input field
const inputRefs = useRef([]);

// Initialize refs
inputRefs.current = inputs.map((_, i) => inputRefs.current[i] ?? React.createRef());
// State to store error messages
const [errors, setErrors] = useState([]);
const [whatsapps,setWhatsapps] = useState([]);
  useEffect(() => {
    if (onAddressSaved === true) {
      let name = cardDetails?.customize?.name;
      const cardID = cardDetails?.design?.card_id;
      const user_cardID = cardId;
      let key = process.env.REACT_APP_rozarpayKey;

      request
        .post("/create_order", { card_id: cardID })
        .then((res) => {
          let amount = res?.data?.amount;
          let orderId = res?.data?.id;
          var options = {
            key: key,
            amount: amount,
            currency: "INR",
            name: name,
            description: "Test Transaction",
            image: "https://example.com/your_logo",
            order_id: orderId,
            handler: function (response) {
              request
                .post("/create_payment", {
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_signature: response.razorpay_signature,
                  payment_amount: res?.data?.amount / 100,
                  card_detail_id: cardId,
                })
                .then((res) => {
                  if (res?.data?.id) {
                    toast.success("Payment Successfull");
                    setactiveTab("myOrders");
                  }
                })
                .catch((err) => {
                  console.log("ERR ", err);
                  toast.error("Payment failed, try again");
                });
            },
            prefill: {
              name: { name },
              email: "gaurav.kumar@example.com",
              contact: { cardPhoneNumber },
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
          };
          var rzp1 = new window.Razorpay(options);
          rzp1.open();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setOnAddressSaved(false);
        });
    }
  }, [onAddressSaved]);

  useEffect(() => {
    if (handleForm) {
      let aboutIndex = handleForm.findIndex(
        (d) => d.handle_name === "about_yourself"
      );

      if (aboutIndex !== -1) {
        setAboutYourself(handleForm[aboutIndex].account_value);
      }
    }
  }, [handleForm]);

  const onChange = (e, index, isAbout) => {
    const value = e.target.value;
    let data = [...visibleFields];
    if (isAbout === "about") {
      let aboutIndex = data.findIndex(
        (d) => d.handle_name === "about_yourself"
      );
      if (aboutIndex !== -1) {
        data[aboutIndex].account_value = value;
        setvisibleFields(data);
        setAboutYourself(value);
      }
    } else {
      data[index].account_value = value;
      if (data[index].handle_name === "phone_number" && sameAsWhatsApp) {
        data.forEach((fields) => {
          if (fields?.handle_name === "whatsapp_number") {
            fields.account_value = value;
          }
        });
      }
      setvisibleFields(data);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeAddressModal = () => {
    setShowAddressModal(false);
  };

  const openAddressModal = () => {
    setShowAddressModal(true);
  };

  const addNewHandle = (handleId) => {
    let handleData = handleList.filter((handle) => handle.id === handleId);
    handleData = handleData[0];
    if(handleData.id == "phone_number") {
      handleData = {
        handle_icon:"https://snaptap-logos.s3.ap-south-1.amazonaws.com/icons/call.svg",
        handle_name:"phone_number",
        handle_placeholder:"Enter Phone Number",
        handle_validation:"^[0-9]{10}$",
        id : "phone_number",
        isWhatsapp : false,
        account_value: "",
      }
    }else{
      handleData = {
        ...handleData,
        account_value: "", // Set account_value to an empty string for other handles
      };
    }
    
    setInputs(prevInputs => [
      ...prevInputs.slice(0, -1),   // All elements except the last one
      handleData,                   // The new handleData
      prevInputs[prevInputs.length - 1]
    ]);
    closeModal();
  };

  const onSubmitHandles = (e, isAddressSelection) => {
    handleSubmission(e);
    // find Mobile No
    let phoneNum = inputs.filter(
      (d) => d.handle_name === "phone_number" && d.account_value.length === 10
    );  
    console.log(phoneNum[0].account_value,"phonenum");
    setCardPhoneNumber(phoneNum[0].account_value);
    openAddressModal(true);
  };

  const fetchAddress = async (latitude, longitude, index, e) => {
    const apiKey = "AIzaSyCqyhR1oW15YtA2C9ervtkcDW9GaoVzQIY";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
  
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const data = await response.json();
      const results = data.results;
      
      if (results.length > 0) {
        const address = results[0].formatted_address;
        console.log("address ", address);
        const location = {
          id : "location",
          handle_name: "location",
          account_value: address,
          handle_placeholder: "Select Your Location",
          handle_validation: "",
        };
        
        const updatedItems = inputs.map((item, i) =>
          i === index ? location : item
        );
        setInputs(
          prevInputs =>
            prevInputs.map((input, i) =>
              i === index ? { ...input, account_value: address } : input
            )
        );
      } else {
        toast.error("Unable to fetch address.");
      }
    } catch (error) {
      toast.error("Error fetching address.");
    }
  };
  
  const getPosition = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  };
  const fetchLocation = async (e, index) => {
    try {
      const position = await getPosition();
      const { latitude, longitude } = position.coords;
      
      // Assuming fetchAddress is an async function
      await fetchAddress(latitude, longitude, index, e);
    } catch (err) {
      // Handle different types of errors
      if (err.message === 'Geolocation is not supported by this browser.') {
        toast.error("Geolocation is not supported by this browser.");
      } else {
        toast.error("Unable to retrieve location. Please try again.");
      }
  }
}
  const addWhatsapp = (e,index,whatsapp) =>{
    const updatedInputs = [...inputs];
    updatedInputs[index].isWhatsapp = !inputs[index].isWhatsapp;
    setInputs(updatedInputs);
    if(whatsapp){
      inputs[index].isWhatsapp = false
    }else{
      inputs[index].isWhatsapp = true
    }
  }

  // Function to validate all inputs
  const validateInputs = () => {
    let hasErrors = false;
    const newErrors = [];
    inputRefs.current.forEach((ref, index) => {
      const inputElement = ref.current;
      const value = inputElement.value;
      const type = inputElement.type;
      const pattern = inputElement.pattern;
      const urlPattern = /^https?:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/.*)?$/;
      let error = '';
      if(type == "textarea" && value.trim() === ''){
        error = `This field is required.`;
        hasErrors = true;
      }
      if(index <= 1){
      // First check if the input is empty
        if (value.trim() === '') {
          error = `This field is required.`;
          hasErrors = true;
        } else {
          // Apply type-specific validations
          if (type === 'email' && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
            error = `Please enter a valid email address.`;
            hasErrors = true;
          }else if (type === 'tel') {
            // Check if the phone number is exactly 10 digits
            const digits = value.replace(/\D/g, ''); // Remove non-digit characters
            if (digits.length !== 10) {
              error = `Please enter a valid 10 digit number.`;
              hasErrors = true;
            }
          }  else if (type === 'number') {
            const numberValue = parseFloat(value);
            if (isNaN(numberValue)) {
              error = `Please enter a valid number.`;
              hasErrors = true;
            } else if (numberValue < 1 || numberValue > 10) {
              error = `Number must be between 1 and 10.`;
              hasErrors = true;
            }
          }
        }
    }else{

        // Apply type-specific validations
        if (type === 'email' && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          error = `Please enter a valid email address.`;
          hasErrors = true;
        }else if (type === 'tel') {
          // Check if the phone number is exactly 10 digits
          const digits = value.replace(/\D/g, ''); // Remove non-digit characters
          if (digits.length !== 10) {
            error = `Please enter a valid 10 digit number.`;
            hasErrors = true;
          }
        }  else if (type === 'number') {
          const numberValue = parseFloat(value);
          if (isNaN(numberValue)) {
            error = `Please enter a valid number.`;
            hasErrors = true;
          } else if (numberValue < 1 || numberValue > 10) {
            error = `Number must be between 1 and 10.`;
            hasErrors = true;
          }
        }else if(type !== 'email' && type !== 'tel' && type !== 'number' && pattern != undefined && pattern != ""){
          if(value.trim() !== ''){
            if(!urlPattern.test(value)){
              error = `Please enter a valid URL e.g - https://www.example.com/id`;
              hasErrors = true;
            }
          }
        }
    }
      newErrors[index] = error;
    });
    setErrors(newErrors);
    return !hasErrors;
  };

  const handleSubmission = (e) =>{
    e.preventDefault();
    if (validateInputs()) {
      let nonEmptyValues;
      if(handleValues != null && handleValues.length > 0) {
        nonEmptyValues = handleValues.filter(item => item.account_value.trim() !== '');
      }else{
        nonEmptyValues = inputs.filter(item => item.account_value.trim() !== '');
      }
      const updatedValues = [...nonEmptyValues];
      submitHandles(updatedValues);
      // Add your form submission logic here
    }
  }
   // Function to handle input change
   const handleChangeError = (index) => {
    const updatedInputs = [...inputs];
    const value = inputRefs.current[index].current.value;
    updatedInputs[index].account_value = value; // Update the value in the inputs array

    setInputs(updatedInputs);
    setHandleValues(updatedInputs);
    setErrors(prevErrors => {
      const newErrors = [...prevErrors];
      newErrors[index] = ''; // Clear error for the specific input
      return newErrors;
    });
  };
  return (
    <fieldset className="cardFieldSet3">
      <div style={{ display: "flex", justifyContent: "end" }}>
        <div class="text-right">
          <button
            class="btn tab1-btn edit-button"
            type="button"
            onClick={openModal}
          >
            <i class="fa fa-edit" aria-hidden="true"></i>&nbsp; <span>Add</span>
          </button>
        </div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div class="row">
          {inputs.map((input,i) =>(
            input.handle_name == "about_yourself"
            ?
            <div class="col-md-12">
            <div class="form-group">
              <InputComponent elem={input}  index={i} ref={inputRefs.current[i]} onChange={() => handleChangeError(i)}/>
              {errors[i] && (
                <div style={{ color: 'red' }}>{errors[i]}</div>
              )}
            </div>
          </div>
          :
            <div
              class="col-md-4"
              style={{
                marginBottom: "16px",
              }}
              key={i}
            >
              <div className="form-group">
                <InputComponent elem={input}  index={i} ref={inputRefs.current[i]} onChange={() => handleChangeError(i)}/>
                {input.handle_name === "phone_number" &&
                  <label style={{display: "flex", gap: "10px",fontWeight: "400", cursor: "pointer"}}>
                    <input 
                    type="checkbox" 
                    name="number_as_whatsapp" 
                    checked={input.isWhatsapp || false}  
                    onChange={(e) => addWhatsapp(e,i,input.isWhatsapp)}/>
                    use same number as whatsapp number
                  </label>
                }
                {input.handle_name === "location" &&
                  <div style={{color: "blue",cursor: "pointer"}} onClick={(e) => fetchLocation(e,i)}>use current location</div>
                }
                {errors[i] && (
                  <div style={{ color: 'red' }}>{errors[i]}</div>
                )}
              </div>
            </div>
          ))}
        </div>

        <div class="row">
          
        </div>

        <div
          class="f1-buttons"
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "end",
            marginTop: "20px",
          }}
        >
          <button
            type="button"
            class="btn btn-previous btns"
            onClick={goToTab2}
          >
            Back
          </button>
          <button
            type="submit"
            class="btn btn-next btns"
            onClick={handleSubmission}
          >
            Submit &nbsp;
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </button>
          {!paymentStatus && 
          <button
            type="submit"
            class="btn btn-next btns"
            onClick={(e) => {
              onSubmitHandles(e, true);
            }}
          >
            Proceed to pay &nbsp;
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </button>
          }
        </div>
      </form>

      <Modal
        closeModal={closeModal}
        showModal={showModal}
        setShowModal={setShowModal}
        addNewHandle={addNewHandle}
        handleList={handleList}
      />

      <SelectAddressModal
        closeModal={closeAddressModal}
        showModal={showAddressModal}
        setShowModal={setShowAddressModal}
        setSelectedAddress={setSelectedAddress}
        cardId={cardId}
        showPreviewButton={showPreviewButton}
        setOnAddressSaved={setOnAddressSaved}
      />
    </fieldset>
  );
};

export default CustomizeLandingPage;
